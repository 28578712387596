import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = (changePassword) =>
  withFormik({
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required("This field is required"),
      // .nullable()
      lastName: Yup.string()
        .trim()
        .required("This field is required"),
      // .nullable(),
      email: Yup.string()
        .required("This field is required")
        .email().trim()
        .nullable(),
      password: changePassword
        ? Yup.string()
          .min(8, "Password Must have 8 characters long")
          .required("This field is required")
          .nullable()
        : Yup.string()
          .min(8 , "Password Must have 8 characters long")
          // .required("This field is required")
          .nullable(),
      confirm_password: changePassword
        ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Password must Match")
          .required("This field is required")
          .trim()
          .nullable()
        : Yup.string()
          .oneOf([Yup.ref("password"), null], "Password must Match")
          // .required("This field is required")
          .trim()
          .nullable(),
      adminType: Yup.string()
        .required("This field is required")
        .nullable(),
    }),
    validate: values => {
      const errors = {};
      const pattern = new RegExp(".*[@#$%^&+=]");
      const pattern2 = new RegExp("(?=.*[A-Z])");
      const pattern3 = new RegExp("(?=.*[0-9])");

      if (values.password) {
        if (values.password.length < 8) {
          errors.password = "password must have 8 characters";
        } else if (!pattern.test(values.password)) {
          errors.password = `password must have at-least one special character `;
        } else if (!pattern2.test(values.password)) {
          errors.password = `password must have at-least one Capital Letter `;
        } else if (!pattern3.test(values.password)) {
          errors.password = `password must have at-least one Number `;
        }
      }

      if (
        values.confirm_password &&
        values.password !== values.confirm_password
      ) {
        errors.confirm_password = "password not matched";
      }
      return errors;
    },
    mapPropsToValues: (props) => ({
      firstName: props.editedData
        ? props && props.editedData && props.editedData.firstName
        : "",
      lastName: props.editedData
        ? props && props.editedData && props.editedData.firstName
        : "",
      // password: props.editedData
      //   ? props && props.editedData && props.editedData.password
      //   : "",
      adminType: props.editedData
        ? props && props.editedData && props.editedData.adminType
        : "",
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
  });

export default formikEnhancer;
